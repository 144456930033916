import { useClient } from '@/contexts/ClientContext'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import { ArrowRightOutlined, EditFilled } from '@ant-design/icons'
import Cookies from 'js-cookie'
import { Modal, Alert, Button, Divider } from 'antd'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { MapsPlacesAutocomplete } from '../MapsPlacesAutocomplete'
import {
  StyledText,
  StyledSpace,
  StyledArrow,
  StyledTextDelivery,
  AddressInfoTopBarWithoutAddress,
  AddressInfoCategoryTitle,
  AddressInfoCategorySmallDescription,
  StyledBtn,
  ModalBottomContent,
  ModalTopContent,
  AutoCompleteWrapper,
} from './styles'
import { CurrentLocationButton } from '../CurrentLocationButton'
import { CustomerAddressProps } from '@/Props/CustomerAddressProps'
import api from '@/services/api'
import { useSession } from 'next-auth/client'
import * as Sentry from '@sentry/nextjs'
import { AdsProps, StrapiCategoryProps } from '@/Props/HomePage/ProductSectionProps'
import cmsApi from '@/services/cmsApi'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { CategoryProps } from '@/Props/CategoryProps'
import { isAfter, isBefore } from 'date-fns'
import VisibilitySensor from 'react-visibility-sensor'
import { SponsoredTag } from '@/components/HomePage/HomePagePrincipalBanner/styles'
import sendSponsorInfoToGTM from '@/utils/sendSponsorInfoToGTM'
import { TypesenseCategoryProps } from '@/components/CategoryTypesense'
import Link from 'next/link'

interface AddressInfoTopBar {
  categoryTitle?: string
  selectedCategory?: any
  typesenseCategory?: TypesenseCategoryProps | CategoryProps
}

function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined'

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}
const AddressInfoTopBar = ({
  categoryTitle,
  selectedCategory,
  typesenseCategory,
}: AddressInfoTopBar) => {
  const router = useRouter()
  const { address, isClientLoading, addressName } = useClient()
  const {
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeClientAddressId,
    changeAddressName,
    changeAddressData,
    changeIsLoading,
    categorySelected,
    changeDeliveryZoneError,
    changeAddressError,
    district,
    changeClientFromIslands,
  } = useClient()
  const [isChangeAddressModalVisible, setIsChangeAddressModalVisible] = useState(false)
  const handleChange = async (Address) => {
    //console.log(address)
    changeAddressName('')
    setLocalAddress(Address)
  }
  const [isAddressDeleted, setIsAddressDeleted] = useState(false)

  const [addressData, setAddressData] = useState<CustomerAddressProps[]>([])
  const [session] = useSession()
  const [localAddress, setLocalAddress] = useState('')
  const [cmsCategorie, setCmsCategorie] = useState<StrapiCategoryProps>()
  const [adToShow, setAdToShow] = useState<AdsProps>()
  const sendDataToGTM = useGTMDispatch()
  const { width } = useWindowDimensions()
  const [adsSentToGTM, setAdsSentToGTM] = useState<string[]>([])

  const handleSelect = async (address: string, placeId: string) => {
    const response = await googleMapsAutocompleteParser({ placeId: placeId })
    if (response.postal_code && response.postal_code.length > 0) {
      // const splitedPostalCode = response.postal_code.split('-')
      // if (
      //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) < 9000
      // ) {
      changeIsLoading(true)
      changeClientAddressId(placeId)
      if (response.city && response.street) {
        changeClientAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        changeClientAddress(response.street)
      } else if (response.city) {
        changeClientAddress(response.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCity(response.city)
      changeClientDistrict(response.district)
      changeClientCountry(response.country)
      changeClientPostalCode(response.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      if (addressData) {
        const getAdress = addressData.find(
          (address) => address.address == response.formatted_address
        )
        if (getAdress) {
          changeAddressName(getAdress.address_name)
        }
      }
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)

      setIsChangeAddressModalVisible(false)
      setIsAddressDeleted(false)
      changeDeliveryZoneError(false)
      // } else {
      //   changeDeliveryZoneError(true)
      //   setIsChangeAddressModalVisible(false)
      // }
    } else {
      const responseMaps = await googleMapsAutocompleteParser({
        lat: response.lat.toString(),
        lng: response.lng.toString(),
      })

      changeIsLoading(true)
      changeClientAddressId(placeId)
      if (responseMaps.city && responseMaps.street) {
        changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        changeClientAddress(responseMaps.street)
      } else if (responseMaps.city) {
        changeClientAddress(responseMaps.city)
      }
      changeClientCity(responseMaps.city)
      changeClientDistrict(responseMaps.district)
      changeClientCountry(responseMaps.country)
      changeClientPostalCode(responseMaps.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      if (addressData) {
        const getAdress = addressData.find(
          (address) =>
            address.latitude == response.lat.toString() &&
            address.longitude == response.lng.toString()
        )
        if (getAdress) {
          changeAddressName(getAdress.address_name)
        }
      }
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)

      setIsChangeAddressModalVisible(false)
      setIsAddressDeleted(false)
      changeDeliveryZoneError(false)
    }
  }
  useEffect(() => {
    if (district && district != '') {
      if (district == 'Madeira') {
        changeClientFromIslands('madeira')
      } else if (district == 'Açores' || district == 'Azores') {
        changeClientFromIslands('açores')
      } else {
        changeClientFromIslands('')
      }
    }
  }, [district])

  const selectAddress = (Address: CustomerAddressProps) => {
    changeIsLoading(true)
    changeAddressName(Address.address_name)
    changeClientAddress(Address.address)
    changeClientAddressId(Address.address_id)
    changeClientCity(Address.city)
    changeClientDistrict(Address.district)
    changeClientCountry(Address.country)
    changeClientPostalCode(Address.postal_code)
    changeClientCoordinates({
      latitude: Address.latitude,
      longitude: Address.longitude,
    })
    //console.log('city', Address.city)
    setIsChangeAddressModalVisible(false)
    setIsAddressDeleted(false)
    changeIsLoading(false)
  }

  useEffect(() => {
    setLocalAddress(address)
  }, [address])

  const deleteAddress = () => {
    /* changeClientAddressId('')
    //changeClientAddress('')
    changeAddressName('')
    changeClientCity('')
    changeClientDistrict('')
    changeClientCountry('')
    changeClientPostalCode('')
    changeClientCoordinates({
      latitude: '',
      longitude: '',
    }) */
    setLocalAddress('')
    setIsAddressDeleted(true)
    //clearCart()
    //setIsChangeAddressModalVisible(true)
    //router.push('/')
  }
  useEffect(() => {
    const getAddressInfo = async () => {
      if (session) {
        try {
          const prop = await api.get<CustomerAddressProps[]>(
            `/customers/${session.customer.id}/addresses`
          )
          setAddressData(prop.data)
          changeAddressData(prop.data)
          const defaultAddress = prop.data.find((each) => each.is_default)
          if (address) {
            prop.data.map((eachAddress) => {
              if (eachAddress.address == address) {
                changeIsLoading(true)
                Cookies.set('clientAddress', eachAddress.address, { expires: 60 })
                changeAddressName(eachAddress.address_name)
                changeClientAddressId(eachAddress.address_id)
                changeClientAddress(eachAddress.address)
                changeClientCity(eachAddress.city)
                changeClientDistrict(eachAddress.district)
                changeClientCountry(eachAddress.country)
                changeClientPostalCode(eachAddress.postal_code)
                changeClientCoordinates({
                  latitude: eachAddress.latitude,
                  longitude: eachAddress.longitude,
                })
                changeIsLoading(false)
              }
            })
          }
          // } else if (defaultAddress) {
          //   changeIsLoading(true)
          //   Cookies.set('clientAddress', defaultAddress.address)
          //   changeAddressName(defaultAddress.address_name)
          //   changeClientAddressId(defaultAddress.address_id)
          //   changeClientAddress(defaultAddress.address)
          //   changeClientCity(defaultAddress.city)
          //   changeClientDistrict(defaultAddress.district)
          //   changeClientCountry(defaultAddress.country)
          //   changeClientPostalCode(defaultAddress.postal_code)
          //   changeClientCoordinates({
          //     latitude: defaultAddress.latitude,
          //     longitude: defaultAddress.longitude,
          //   })
          //   changeIsLoading(false)
          // } else {
          //   changeIsLoading(true)
          //   Cookies.set('clientAddress', prop.data[0].address)
          //   changeAddressName(prop.data[0].address_name)
          //   changeClientAddressId(prop.data[0].address_id)
          //   changeClientAddress(prop.data[0].address)
          //   changeClientCity(prop.data[0].city)
          //   changeClientDistrict(prop.data[0].district)
          //   changeClientCountry(prop.data[0].country)
          //   changeClientPostalCode(prop.data[0].postal_code)
          //   changeClientCoordinates({
          //     latitude: prop.data[0].latitude,
          //     longitude: prop.data[0].longitude,
          //   })
          //   changeIsLoading(false)
          // }
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
    getAddressInfo()
  }, [session, address])

  useEffect(() => {
    setAdToShow(null)
    if (selectedCategory && selectedCategory.cms_id && !selectedCategory.is_sub_category) {
      try {
        cmsApi
          .get<StrapiCategoryProps>(`categories/${selectedCategory.cms_id}`)
          .then(({ data: categories }) => {
            setCmsCategorie(categories)
          })
      } catch (error) {
        // Sentry.captureException(error)
        //console.log('148', error)
      }
    } else if (categorySelected && categorySelected.cms_id && !categorySelected.is_sub_category) {
      try {
        cmsApi
          .get<StrapiCategoryProps>(`categories/${categorySelected.cms_id}`)
          .then(({ data: categories }) => {
            setCmsCategorie(categories)
          })
      } catch (error) {
        // Sentry.captureException(error)
        //console.log('148', error)
      }
    }
  }, [selectedCategory, categorySelected])

  useEffect(() => {
    if (cmsCategorie) {
      if (cmsCategorie.multiple_content_categories) {
        let adsFound = false
        cmsCategorie.multiple_content_categories.map((eachAd) => {
          if ((eachAd.image_ads || eachAd.banner_ads) && eachAd.name_ads && !adsFound) {
            if (eachAd.begin_date_ads && eachAd.end_date_ads) {
              if (
                isAfter(new Date(), new Date(eachAd.begin_date_ads)) &&
                isBefore(new Date(), new Date(eachAd.end_date_ads))
              ) {
                adsFound = true
                setAdToShow(eachAd)
              }
            }
          }
        })
      }
    }
  }, [cmsCategorie])

  if (isClientLoading) {
    return null
  }

  if ((!address || address == '') && !typesenseCategory) {
    if (adToShow) {
      return (
        <VisibilitySensor
          onChange={(isVisible) =>
            sendSponsorInfoToGTM(
              isVisible,
              adToShow,
              `category-banner-`,
              adsSentToGTM,
              sendDataToGTM
            )
          }
        >
          <Link href={adToShow.banner_link_ads}>
            <a style={{ position: 'relative' }}>
              <AddressInfoTopBarWithoutAddress
                style={{
                  backgroundImage: `url(${
                    adToShow.banner_ads_mobile && width <= 600
                      ? adToShow.banner_ads_mobile.url
                      : adToShow.banner_ads.url
                  })`,
                }}
              >
                <AddressInfoCategoryTitle level={1}>{categoryTitle}</AddressInfoCategoryTitle>
                <AddressInfoCategorySmallDescription level={5}>
                  Cerveja, vinho e licores entregues à tua porta
                </AddressInfoCategorySmallDescription>
                <AutoCompleteWrapper>
                  <MapsPlacesAutocomplete
                    key={0}
                    address={localAddress}
                    handleChange={handleChange}
                    handleSelect={handleSelect}
                    isAddressDeleted={false}
                  />
                  <StyledBtn type="primary" size="large" icon={<ArrowRightOutlined />}></StyledBtn>
                </AutoCompleteWrapper>
                <CurrentLocationButton />
              </AddressInfoTopBarWithoutAddress>
              <SponsoredTag>Ad</SponsoredTag>
            </a>
          </Link>
        </VisibilitySensor>
      )
    } else {
      return (
        <AddressInfoTopBarWithoutAddress>
          <AddressInfoCategoryTitle level={1}>
            {categoryTitle == '' ? <br /> : categoryTitle}
          </AddressInfoCategoryTitle>
          <AddressInfoCategorySmallDescription level={5}>
            Cerveja, vinho e licores entregues à tua porta
          </AddressInfoCategorySmallDescription>
          <AutoCompleteWrapper>
            <MapsPlacesAutocomplete
              key={0}
              address={localAddress}
              handleChange={handleChange}
              handleSelect={handleSelect}
              isAddressDeleted={false}
            />
            <StyledBtn type="primary" size="large" icon={<ArrowRightOutlined />}></StyledBtn>
          </AutoCompleteWrapper>
          <CurrentLocationButton />
        </AddressInfoTopBarWithoutAddress>
      )
    }
  }

  return (
    <>
      {address && (
        <StyledSpace direction="horizontal" align="center" size={0}>
          <StyledTextDelivery>Entrega</StyledTextDelivery>
          <StyledArrow />
          <StyledText
            type="link"
            size="small"
            onClick={() => {
              setIsChangeAddressModalVisible(true)
            }}
          >
            {addressName != '' || addressName ? (
              <>
                <strong>{addressName}</strong>
                {` (${address})`}
              </>
            ) : (
              `${address}`
            )}
          </StyledText>
        </StyledSpace>
      )}

      {typesenseCategory && (
        <AddressInfoTopBarWithoutAddress
          style={{
            backgroundImage: typesenseCategory.banner ? `url(${typesenseCategory.banner})` : '',
          }}
        >
          <div className="after-hours-banner-wrapper">
            <div className="after-hours-banner-text-wrapper">
              <AddressInfoCategoryTitle className="after-hours-title" level={1}>
                {categoryTitle}
              </AddressInfoCategoryTitle>
              <AddressInfoCategorySmallDescription className="after-hours-subtitle" level={5}>
                Para que não te falte bebida durante a noite.
              </AddressInfoCategorySmallDescription>
              {!address && (
                <>
                  <AutoCompleteWrapper>
                    <MapsPlacesAutocomplete
                      key={0}
                      address={localAddress}
                      handleChange={handleChange}
                      handleSelect={handleSelect}
                      isAddressDeleted={false}
                    />
                    <StyledBtn
                      type="primary"
                      size="large"
                      icon={<ArrowRightOutlined />}
                    ></StyledBtn>
                  </AutoCompleteWrapper>
                  <CurrentLocationButton />
                </>
              )}
            </div>
            <img
              className="moon-after-hours"
              src="/assets/images/moon-after-hours.svg"
              alt="after-hours"
            />
          </div>
        </AddressInfoTopBarWithoutAddress>
      )}
      <Modal
        visible={isChangeAddressModalVisible}
        title="Onde estás?"
        onCancel={async () => {
          if (localAddress == '') {
            changeClientAddress('')

            setIsChangeAddressModalVisible(false)
            setIsAddressDeleted(false)
          } else {
            setIsChangeAddressModalVisible(false)
            setIsAddressDeleted(false)
          }
        }}
        footer={null}
        className="location-modal"
      >
        <ModalTopContent>
          <MapsPlacesAutocomplete
            address={localAddress}
            handleSelect={handleSelect}
            handleChange={handleChange}
            isAddressDeleted={isAddressDeleted}
          />
          <Button type="link" onClick={() => deleteAddress()}>
            Alterar
          </Button>
        </ModalTopContent>
        {isAddressDeleted && session && (
          <div className="new-address">
            {addressData.map((address, index) => {
              return (
                <div key={index}>
                  {index > 0 && <Divider style={{ margin: '15px 0px' }} />}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="button-new-address"
                      onClick={() => {
                        selectAddress(address)
                      }}
                    >
                      <p style={{ fontWeight: 'bold', marginBottom: 5, margin: 0, padding: 0 }}>
                        {address.address_name}
                      </p>
                      <p className="addressInfo">{address.address}</p>
                    </button>
                    <EditFilled
                      onClick={() => router.replace('/cliente/conta')}
                      className="edit-icon"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <ModalBottomContent>
          <Alert
            message="Se alterares a morada os produtos no carrinho podem não estar disponíveis para entrega."
            type="warning"
            showIcon
          />
        </ModalBottomContent>
      </Modal>
    </>
  )
}

export default AddressInfoTopBar
