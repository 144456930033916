import { useClient } from '@/contexts/ClientContext'
import { TiLocationArrow } from 'react-icons/ti'
import { useState } from 'react'
import { AutoDestroyModal } from '../AutoDestroyModal'
import { StyledBtnLocation } from './styles'

const CurrentLocationButton = () => {
  const {
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeClientAddressId,
    changeIsLoading,
  } = useClient()
  const [state, setState] = useState('prompt')

  const getCordinates = (position) => {
    const latlng = {
      lat: parseFloat(position.coords.latitude),
      lng: parseFloat(position.coords.longitude),
    }
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode(
      { location: latlng },
      async (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === 'OK') {
          if (results[0]) {
            const { long_name: city = '' } =
              results[0].address_components.find((c) => c.types.includes('locality')) || {}

            const { long_name: district = '' } =
              results[0].address_components.find((c) =>
                c.types.includes('administrative_area_level_1')
              ) || {}

            const { long_name: postalCode = '' } =
              results[0].address_components.find((c) => c.types.includes('postal_code')) || {}

            const { long_name: country = '' } =
              results[0].address_components.find((c) => c.types.includes('country')) || {}
            changeIsLoading(true)
            changeClientAddressId(results[0].place_id)
            changeClientAddress(results[0].formatted_address)
            changeClientCity(city)
            changeClientDistrict(district)
            changeClientCountry(country)
            changeClientPostalCode(postalCode)
            changeClientCoordinates({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
            changeIsLoading(false)
          }
        }
      }
    )
  }

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getCordinates, () => {
        AutoDestroyModal({
          type: 'error',
          title: 'Tem que ativar a localização do dispositivo',
        })
      })
    } else {
      AutoDestroyModal({
        type: 'error',
        title: 'Tem que ativar a localização do dispositivo',
      })
    }
    /* window.navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
      if (result.state == 'granted') {
        setState(result.state)
      } else if (result.state == 'prompt') {
        setState(result.state)
      } else {
        setState(result.state)
      }
    })

    if (
      (window.navigator.geolocation && state == 'granted') ||
      (window.navigator.geolocation && state == 'prompt')
    ) {
      window.navigator.geolocation.getCurrentPosition(getCordinates)
    } else {
      AutoDestroyModal({
        type: 'error',
        title: 'Tem que ativar a localização do dispositivo',
      })
    } */
  }

  return (
    <StyledBtnLocation onClick={() => handleCurrentLocation()}>
      <TiLocationArrow size={30} />
      Usar localização actual
    </StyledBtnLocation>
  )
}

export { CurrentLocationButton }
