import { Button } from 'antd'
import styled from 'styled-components'

export const StyledBtnLocation = styled.button`
  width: 100%;
  display: none;
  margin-top: 12px;
  padding: 0 22px;
  height: 40px;
  background-color: #f16749;
  border: none;
  color: white;
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;

  /* Neutral / White */

  color: #ffffff;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 350px) {
    display: flex;
    white-space: unset;
    text-overflow: ellipsis;
    padding: 10px 20px;
    height: auto;
  }
`
